import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import Link from '../../atoms/Link';

const ProductCardAction = ({
    className,
    product,
    redirectToProductPage,
    onClickOnCard,
    children,
}) => {
    const onCardClick = (e) => {
        if (onClickOnCard && typeof onClickOnCard === 'function') onClickOnCard(e);
    };

    if (!redirectToProductPage)
        return <div className={cx('grid-cols-inherit', className)}>{children}</div>;

    return (
        <Link
            className={cx('grid-cols-inherit', className)}
            underline={false}
            label={product.name}
            href={product.href}
            onClick={onCardClick}
        >
            {children}
        </Link>
    );
};

export default ProductCardAction;

ProductCardAction.propTypes = {
    className: PropTypes.string,
    product: PropTypes.object,
    redirectToProductPage: PropTypes.bool,
    onClickOnCard: PropTypes.func,
    children: PropTypes.node,
};
