import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import {
    Tooltip as ReakitTooltip,
    TooltipArrow,
    TooltipReference,
    useTooltipState,
} from 'reakit/Tooltip';

const Tooltip = ({
    uniqueId,
    anchorClassName,
    tooltipBgColor,
    arrowFillColor,
    placement,
    showOnHover,
    tooltipContent,
    children,
}) => {
    // Prefix is required to target tooltip with CSS selector (see tailwind.scss)
    const id = 'aria-tooltip-' + uniqueId;

    const tooltipState = useTooltipState({
        baseId: id,
        padding: 5,
        gutter: 12,
        visible: showOnHover ? undefined : true,
        placement: placement,
        unstable_fixed: true,
        unstable_flip: true,
    });

    useEffect(() => {
        tooltipState.place(placement);
    }, [tooltipState.place, placement]);

    return (
        <>
            <TooltipReference
                {...tooltipState}
                as='div'
                className={cx(anchorClassName, {
                    'cursor-pointer': showOnHover,
                })}
            >
                {children}
            </TooltipReference>
            {tooltipContent && (
                <ReakitTooltip
                    baseId={id}
                    {...tooltipState}
                    visible={showOnHover ? tooltipState.visible : true}
                    unstable_portal={false}
                    className={cx(
                        tooltipBgColor,
                        'max-w-sm rounded-sm p-2 text-center text-ui-black',
                        placement === 'top' ? 'shadow-up' : 'shadow-down'
                    )}
                >
                    <TooltipArrow {...tooltipState} className={cx('h-6 w-6', arrowFillColor)} />
                    {typeof tooltipContent === 'string' ? (
                        <p className='mb-0'>
                            <small>{tooltipContent}</small>
                        </p>
                    ) : (
                        tooltipContent
                    )}
                </ReakitTooltip>
            )}
        </>
    );
};

Tooltip.displayName = 'Tooltip';

Tooltip.defaultProps = {
    tooltipBgColor: 'bg-brand-primary-300',
    arrowFillColor: 'fill-brand-primary-300',
    placement: 'auto',
    showOnHover: true,
};

Tooltip.propTypes = {
    uniqueId: PropTypes.string.isRequired,
    anchorClassName: PropTypes.string,
    tooltipClassName: PropTypes.string,
    tooltipBgColor: PropTypes.string,
    arrowFillColor: PropTypes.string,
    placement: PropTypes.oneOf([
        'auto-start',
        'auto',
        'auto-end',
        'top',
        'bottom',
        'left',
        'right',
    ]),
    showOnHover: PropTypes.bool,
    /** Text to show inside the tooltip */
    tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Tooltip width */
    children: PropTypes.node,
};

export default Tooltip;
