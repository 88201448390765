import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { get_ } from 'sf-modules';
import Rating from '../Rating';
import Tooltip from '../../molecules/Tooltip';
import ProductPriceForQuantity from '../Product/ProductPriceForQuantity';
import ProductCardCaption from '../ProductCardCaption';

const ProductBrandAndName = ({ product, cardType, context }) => {
    const brandClassNames = cx(
        'mb-1 lg:mb-2 text-left text-ui-gray-500 text-caption font-body font-body-regular'
    );
    const nameClassNames = cx(
        'mb-1 sm:mb-0 text-left text-ui-gray-900 text-small font-body font-body-bold overflow-hidden grow',
        {
            'h-8.5': cardType === 'catalog',
        }
    );

    return (
        <>
            <div className='flex flex-col'>
                {product.brand ? (
                    <div className={brandClassNames}>{product.brand}</div>
                ) : !product.brand && cardType === 'catalog' ? (
                    <div className='h-6' />
                ) : (
                    ''
                )}
                <Tooltip
                    uniqueId='product-full-name'
                    showOnHover={true}
                    placement='bottom'
                    tooltipContent={
                        product.name.length > 40 &&
                        context !== 'cart' &&
                        context !== 'checkout' &&
                        context !== 'pos' &&
                        context !== 'success'
                            ? product.name
                            : null
                    }
                >
                    <div className={nameClassNames}>
                        {product.name.length > 40 &&
                        context !== 'cart' &&
                        context !== 'checkout' &&
                        context !== 'pos' &&
                        context !== 'success'
                            ? product.name.substring(0, 40) + '...'
                            : product.name}
                    </div>
                </Tooltip>
            </div>
        </>
    );
};

const ProductCardBody = ({
    product,
    showRatings,
    className,
    styles,
    context,
    cardType,
    hideDiscounts,
}) => {
    const cardBodyClass = cx('w-full flex flex-col', className);
    const showCaption =
        cardType === 'informative' &&
        (context === 'checkout' || context === 'success' || context === 'profileOrder');

    return (
        <>
            <div className={cardBodyClass} style={styles}>
                <ProductBrandAndName product={product} context={context} cardType={cardType} />

                {showRatings && (
                    <Rating rating={product.reviewAverage || 0} review={product.reviewCount || 0} />
                )}

                <ProductPriceForQuantity
                    pricesWrapperClassName='flex-col'
                    discountsWrapperClassName='mb-0.5'
                    context={context}
                    product={product}
                    hideDiscounts={hideDiscounts}
                />

                {!get_(product, ['isGift']) && showCaption && (
                    <div className='mt-2 hidden lg:block'>
                        <ProductCardCaption
                            promotion={product.firstApplicableRule}
                            appliedBenefits={product.appliedBenefits}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

ProductCardBody.defaultProps = {
    showRatings: false,
    hideDiscounts: false,
};

ProductCardBody.propTypes = {
    product: PropTypes.object,
    className: PropTypes.string,
    styles: PropTypes.object,
    showRatings: PropTypes.bool,
    context: PropTypes.string,
    cardType: PropTypes.string,
    hideDiscounts: PropTypes.bool,
};

export default ProductCardBody;
