import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import Image from '../Image';
import IconCamera from '../../../../assets/icon-camera.svg';

const BLUR_DATA_URL =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNcWg8AAc8BJpg2zxQAAAAASUVORK5CYII=';
const GIFT_IMAGE =
    'https://us-central1-yema-cdn.cloudfunctions.net/cdn/api/v1/yema-plm/images/view/assets/regalito-yema_ec2e304b-bf69-4226-a853-65b2237e6468';

const ProductCardImage = ({
    product,
    style,
    className,
    imageClassName,
    size,
    fadeIfUnavailable,
    noImageText,
}) => {
    const shouldFadeImage =
        fadeIfUnavailable &&
        (product?.availability?.status === 'stock-out' ||
            product?.availability?.status === 'unavailable');

    const mainImage = product.bundle?.mainImage || product.mainImage;
    const noImage = Boolean(!product.isGift && !mainImage);

    return (
        <div className={cx('relative flex', className)} style={style}>
            {noImage ? (
                <div className='absolute flex h-full w-full flex-col justify-center'>
                    <Image
                        src={IconCamera}
                        alt='Icono de cámara'
                        width={size === 'xs' ? 50 : 140}
                        height={size === 'xs' ? 50 : 140}
                        placeholder='blur'
                        blurDataURL={BLUR_DATA_URL}
                    />
                    <span
                        className='mt-2 px-1 text-center font-body font-body-medium sm:mt-3'
                        style={{
                            fontSize: size === 'xs' ? '9px' : '11px',
                            lineHeight: size === 'xs' ? '10px' : '.8rem',
                        }}
                    >
                        {noImageText && <>Producto en sesión de fotos</>}
                    </span>
                </div>
            ) : (
                <Image
                    layout='fill'
                    objectFit={!mainImage && !product.isGift ? 'contain' : 'cover'}
                    objectPosition='center'
                    sizes='(min-width: 768px) 260px, 108px'
                    className={cx(imageClassName, 'absolute top-0 left-0 h-full w-full', {
                        'mx-auto h-auto w-1/2': !mainImage && !product.isGift,
                        'h-full w-full': mainImage || product.isGift,
                        'opacity-40': shouldFadeImage,
                    })}
                    src={product.isGift ? GIFT_IMAGE : mainImage}
                    alt={product.mainImageAlt || product.name}
                    placeholder='blur'
                    blurDataURL={BLUR_DATA_URL}
                />
            )}
        </div>
    );
};

ProductCardImage.defaultProps = {
    noImageText: true,
};

ProductCardImage.propTypes = {
    product: PropTypes.object,
    fadeIfUnavailable: PropTypes.bool,
    size: PropTypes.oneOf(['xs', 'small', 'big']),
    style: PropTypes.object,
    className: PropTypes.string,
    imageClassName: PropTypes.string,
    cardType: PropTypes.string,
};

export default ProductCardImage;
