import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import useMedia from 'use-media';
import { get_ } from 'sf-modules';
import { CheckIcon, InformationPromoIcon } from 'icons/solid';
import Caption from '../Caption';

const Link = dynamic(() => import('../Link'), { ssr: false });
const Tooltip = dynamic(() => import('../../molecules/Tooltip'), { ssr: false });
const FullModal = dynamic(() => import('../../organisms/FullModal'), { ssr: false });

const TooltipDiscountContent = ({ tooltipContent, isModal }) => {
    return (
        tooltipContent && (
            <ul className='text-left'>
                {tooltipContent.map((i, index) => (
                    <li key={index} className={!isModal ? 'text-caption' : ''}>
                        {isModal ? (
                            <CheckIcon
                                className={
                                    'my-1 mr-1 h-4 w-4 rounded-full bg-ui-success fill-white stroke-white stroke-2 p-1'
                                }
                            />
                        ) : (
                            <>{i}</>
                        )}
                    </li>
                ))}
            </ul>
        )
    );
};

const DiscountCaption = ({ discounts, isMobile, context }) => {
    const [showModal, setShowModal] = useState(false);
    if (!discounts) return '';

    const discountsLength = discounts.length;

    let discountsToShow = context === 'cart' ? discounts : discounts.slice(0, 1);
    if (!isMobile && discountsLength >= 3 && context === 'cart')
        discountsToShow = discounts.slice(1, 3);
    else if (isMobile && discountsLength >= 2) discountsToShow = [];

    return (
        <div className='mt-1 flex w-full items-center justify-start gap-x-2'>
            {discountsToShow.map((discount, index) => (
                <Caption
                    key={index}
                    icon={
                        <CheckIcon className='my-1 mr-1 h-4 w-4 rounded-full bg-ui-success fill-white stroke-white stroke-2 p-1' />
                    }
                >
                    {discount}
                </Caption>
            ))}
            {(discountsLength >= 3 && !isMobile) ||
            (discountsLength >= 2 && !isMobile && context !== 'cart') ? (
                <Tooltip
                    uniqueId='tooltip-discounts'
                    anchorClassName='min-w-max p-1 text-caption font-body font-body-bold underline truncate'
                    placement='right'
                    showOnHover={true}
                    tooltipContent={<TooltipDiscountContent tooltipContent={discounts} />}
                >
                    Más promos
                </Tooltip>
            ) : (
                discountsLength > 1 &&
                isMobile && (
                    <>
                        <Link
                            className='text-caption text-black'
                            onClick={() => setShowModal(true)}
                        >
                            <CheckIcon
                                className={
                                    'my-1 mr-1 h-4 w-4 rounded-full bg-ui-success fill-white stroke-white stroke-2 p-1'
                                }
                            />
                            Ver descuentos aplicados
                        </Link>

                        {showModal && (
                            <FullModal
                                isOpen={showModal}
                                onClose={() => setShowModal(false)}
                                title='Descuentos aplicados'
                                headerBackgroundColor='bg-brand-primary-300'
                                headerShadow={true}
                                overlayClassName='h-screen bg-ui-white'
                            >
                                <TooltipDiscountContent tooltipContent={discounts} isModal={true} />
                            </FullModal>
                        )}
                    </>
                )
            )}
        </div>
    );
};

const ProductCardCaption = ({ availability, promotion, appliedBenefits, context }) => {
    const isMobile = useMedia({ maxWidth: 640 });
    const productIsUnavailable =
        availability &&
        !availability.availableOnZipCode &&
        get_(availability, ['status'], '') === 'unavailable';

    if (productIsUnavailable)
        return (
            <Caption
                className='mt-1.5'
                icon={<InformationIcon className='h-5 w-5 text-brand-tertiary-700' />}
            >
                Producto no disponible en tu ubicación
            </Caption>
        );
    else if (promotion && promotion.title)
        return (
            <Caption
                className='mt-1.5'
                icon={
                    promotion.icon || <InformationPromoIcon className='h-5 w-5 text-ui-success' />
                }
            >
                {promotion.title}
            </Caption>
        );
    else if (appliedBenefits && appliedBenefits.length) {
        const discountsApplied = appliedBenefits.map((d) => d.title);
        return (
            <DiscountCaption discounts={discountsApplied} isMobile={isMobile} context={context} />
        );
    }
    return '';
};

ProductCardCaption.defaultProps = {
    appliedBenefits: [],
};

ProductCardCaption.propTypes = {
    availability: PropTypes.shape({
        availableOnZipCode: PropTypes.bool,
        status: PropTypes.string,
    }),
    promotion: PropTypes.shape({
        title: PropTypes.string,
        icon: PropTypes.string,
    }),
    appliedBenefits: PropTypes.array,
    context: PropTypes.string,
};

export default React.memo(ProductCardCaption);
