import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';

const Caption = ({ className, classNameImg, icon, iconAlt, children }) => {
    return (
        <p className={cx(className, 'mb-0 flex items-center text-left text-caption')}>
            {typeof icon === 'string' ? (
                <img src={icon} alt={iconAlt} className={cx(classNameImg, 'mr-1')} />
            ) : (
                icon
            )}
            <span>{children}</span>
        </p>
    );
};

Caption.propTypes = {
    className: PropTypes.string,
    iconClassName: PropTypes.string,
    classNameImg: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    iconAlt: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

Caption.defaultProps = {
    iconAlt: 'info',
    classNameImg: 'w-6 h-6',
};

export default Caption;
