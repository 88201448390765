import * as PropTypes from 'prop-types';
import cx from 'classnames';
import Badge from '../Badge';

const BadgeGroup = ({ product, className, style, cardType }) => {
    const isUnavailable = !product?.availability?.itemsAvailable;

    if (!product) return '';

    let badge = { type: undefined, label: undefined };

    if (cardType !== 'informative' && isUnavailable) badge = { type: 'stockout', label: 'Agotado' };
    else if (product.isGift) badge = { type: 'initial', label: 'Regalo' };
    else if (product?.badgePromotion && cardType !== 'informative')
        badge = { type: 'initial', label: product.badgePromotion };
    else if (
        product?.badgePromotion &&
        (cardType === 'catalog' || (cardType !== 'informative' && product.productsDiscounted > 0))
    )
        badge = { type: 'initial', label: product.badgePromotion };

    if (!badge.label) return '';

    return (
        <Badge
            className={cx('absolute top-0 left-0 z-10', className)}
            style={style}
            type={badge.type}
        >
            {badge.label}
        </Badge>
    );
};

BadgeGroup.propTypes = {
    product: PropTypes.object,
    style: PropTypes.object,
    className: PropTypes.string,
    cardType: PropTypes.string,
};

export default BadgeGroup;
